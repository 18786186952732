import { createRouter, createWebHistory } 	from '@ionic/vue-router'
import { RouteRecordRaw } 					from 'vue-router'
import store 								from './store'
import { Capacitor }						from '@capacitor/core'

import authRoutes	from './router-modules/auth.routes'
import staticRoutes from './router-modules/static.routes'
import feedRoutes   from './router-modules/feed.routes'
import coursesRoutes from './router-modules/courses.routes'
import profileRoutes from './router-modules/profile.routes'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: localStorage.getItem('gamechangers_is_onboarded') == 'true' ? {name: 'Feed - For you'} : {name: 'Onboarding'}
	},

	...authRoutes,
	...feedRoutes,
	...staticRoutes,
	...coursesRoutes,
	...profileRoutes
]

const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes
})

const platform = Capacitor.getPlatform()

router.beforeEach((to, from, next) => {
	window.postMessage(to.fullPath, '*')

	if (platform == 'ios' || platform == 'android') {
		to.name == 'Feed - For you' || to.name == 'Feed - Following'
			? screen.orientation.unlock()
			: screen.orientation.lock('portrait')
	}


	const isLoggedIn = store.getters['auth/isLoggedIn']

	if (to.meta.requiresAuth === true) {
		if (isLoggedIn)
			return next()
		else
			return next({name: 'Login'})
	} else if (to.meta.requiresAuth === false) {
		if (!isLoggedIn)
			return next()
		else
			return next({name: 'Feed - For you'})
	} else {
		return next()
	}
})

export default router
