/* eslint-disable */
import axios from 'axios'
import store from '@/plugins/app/_config/store'

let isRefreshing = false
let refreshHook = Promise.resolve(null)

async function _unauthorised(error: any) {
	if (error?.response?.data?.error == 'The token could not be parsed from the request') {
		return store.dispatch('auth/logout')
	}

	if (!error.response || error.response.status != 401) {
		return Promise.reject(error)
	}
	if (
		error.response.data.error == 'Token not provided' ||
		error.response.data.error == 'Token Signature could not be verified.' ||
		error.response.data.error == 'Token has expired and can no longer be refreshed' ||
		error.response.data.error == 'Wrong number of segments' ||
		error.response.data.error == 'The token has been blacklisted.') {
		return store.dispatch('auth/logout')
	}

	if (!isRefreshing) {
		isRefreshing = true

		refreshHook = new Promise((resolve, reject) => {
			store.dispatch('auth/refreshToken')
				.then((token) => resolve(token))
				.catch((error) => reject(error))
		})
	}

	try {
		const token = await refreshHook

		error.config.headers = {
			Authorization: `Bearer ${token}`,
		}

		const data = JSON.parse(error?.config?.data || '{}')
		error.config.data = data

		return Promise.resolve(await axios.request(error.config))
	} catch (error) {
		return store.dispatch('auth/logout')
	} finally {
		isRefreshing = false
	}
}

axios.interceptors.response.use(
	(response) => response,
	(error) => _unauthorised(error),
)
