<template>
	<ion-page>
		<ion-content color="danger">
			
			<div class="wrapper">
				<h1>{{error.status}}</h1>
				<h3>{{error.message}}</h3>
				<p>Please, wait. We have technical difficulties.</p>
				<p>Prosím, počkajte. Máme technické problémy.</p>
			</div>

		</ion-content>
	</ion-page>
</template>

<script>
export default {
	props: {
		status: {
			type: Number
		},
		statusText: {
			type: String
		}
	},

	data() {
		return {
			error: {status: 'ERROR'}
		}
	},

	mounted () {
		if (this.status) {
			this.error = {
				status: `ERROR ${this.status}`,
				message: this.statusText
			}
			localStorage.errorStatus = this.status
			localStorage.errorMessage = this.statusText
		}	
		else {
			if (!localStorage.errorStatus) return
			this.error = {
				status: `ERROR ${localStorage.errorStatus}`,
				message: localStorage.errorMessage
			}
		}
	},
}
</script>

<style lang="sass" scoped>
.wrapper
	width: 100%
	max-width: 1000px
	margin: 0 auto
	height: 100%
	display: flex
	flex-flow: column wrap
	justify-content: center
	align-items: center

	& > * 
		color: white
		text-align: center

	h3 
		margin-bottom: 20px
</style>