<template>
	<div v-if="isVideoProcessing" ref="wrap" class="wrap">
		<ion-spinner name="crescent" color="light" />
		<p>{{progress}}</p>            
	</div>	
</template>

<script>
export default {
	data() {
		return {
			isVideoProcessing: false,
			progress: this.$t('Spracovávam video...')
		}
	},
    
	created() {
		this.eventBus.on('isVideoProcessing', (bool) => {
			if (!bool) {
				this.$refs.wrap.classList.add('complete')
				this.progress = this.$t('Hotovo!')
				setTimeout(() => {
					this.isVideoProcessing = bool
					this.progress = this.$t('Spracovávam video...')
					this.$refs.wrap.classList.remove('complete')
				}, 1500)
			}
			else {
				this.isVideoProcessing = bool
			}
		})
	},

	methods: {
		showVideoProcess(bool) {
			if (!bool) {
				this.$refs.wrap.classList.add('complete')
				this.progress = this.$t('Hotovo!')
				setTimeout(() => {
					this.isVideoProcessing = bool
					this.progress = this.$t('Spracovávam video...')
					this.$refs.wrap.classList.remove('complete')
				}, 1500)
			}
			else {
				this.isVideoProcessing = bool
			}            
		}
	},
}
</script>

<style lang="sass" scoped>
.wrap
    position: absolute
    top: 40px
    right: 4%
    width: 100px
    height: 75px
    border-radius: 10px
    background: #1d1d1d
    z-index: 50
    display: flex
    flex-flow: column wrap
    justify-content: center
    align-items: center

    p   
        margin: 0
        font-size: .75em
        color: #f5f5f5
        text-align: center
        line-height: 1.05em

    ion-spinner
        width: 38px
        height: 38px
        margin-bottom: 1px

.complete
    background: #2dd36f !important

    p
        margin-top: 7.5px
</style>