export default [
	{
		path: '/profile',
		name: 'My profile',
		component: () => import('@/plugins/app@profile/profile/profile.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/profile-settings',
		name: 'Profile settings',
		component: () => import('@/plugins/app@profile/user-settings/user-settings.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/profile-info',
		name: 'Profile info',
		component: () => import('@/plugins/app@profile/profile-info/profile-info.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/change-password',
		name: 'Change password',
		component: () => import('@/plugins/app@profile/change-password/change-password.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/upload-video',
		name: 'Upload video',
		component: () => import('@/plugins/app@profile/video-form/video-form.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/edit-video/:videoId',
		name: 'Edit video',
		component: () => import('@/plugins/app@profile/video-form/video-form.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/my-videos',
		name: 'My videos',
		component: () => import('@/plugins/app@profile/my-videos/my-videos.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/channels',
		name: 'Channel - List',
		component: () => import('@/plugins/lib@channel/list/list.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/channels/:channelId',
		name: 'Channel - Form',
		component: () => import('@/plugins/lib@channel/form/form.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/:nickname',
		name: 'Profile creator',
		component: () => import('@/plugins/app@profile/creator/profile-creator.vue'),
		meta: {requiresAuth: undefined}
	}
]