import { loadingController } from '@ionic/vue'
import { ref } from 'vue'

export default {
	_isLoading: ref(false),
	loader: null,

	isLoading() {
		return this._isLoading.value
	},

	async startLoading(text='Loading') {
		this._isLoading.value = true

		this.loader = await loadingController
			.create({
				message: text
			})

		this.loader.present()
	},

	stopLoading() {
		this._isLoading.value = false
		this.loader?.dismiss()
	}
}

