export default [
	{
		path: '/theme/:themeSlug',
		name: 'Theme',
		component: () => import('@/plugins/lib@theme/theme/theme.vue'),
		meta: {requiresAuth: undefined}
	},

	{
		path: '/course',
		name: '/course/:courseSlug',
		component: () => import('@/plugins/lib@course/course/course.vue'),
		meta: {requiresAuth: undefined}
	},

	{
		path: '/channel/:channelId/payment-method',
		name: 'Payment method',
		component: () => import('@/plugins/app@payments/payment-overview/payment-overview.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/channel/:channelId/payment-method/credit-card/:price',
		name: 'Payment by card',
		component: () => import('@/plugins/app@payments/payment-by-card/payment-by-card.vue'),
		meta: {requiresAuth: true}
	}
]