export default [
	{
		path: '/onboarding',
		name: 'Onboarding',
		component: () => import('@/plugins/app@static/onboarding/onboarding.vue'),
		meta: {requiresAuth: false}
	},

	{
		path: '/eula',
		name: 'Eula',
		component: () => import('@/plugins/app@static/eula/eula.vue'),
		meta: {requiresAuth: undefined}
	},

	{
		path: '/about-us',
		name: 'About us',
		component: () => import('@/plugins/app@static/about-us/about-us.vue'),
		meta: {requiresAuth: undefined}
	},

	{
		path: '/academy',
		name: 'Academy',
		component: () => import('@/plugins/app@static/academy/academy.vue'),
		meta: {requiresAuth: undefined}
	},

	{
		path: '/academy/:courseSlug',
		name: 'Academy course',
		component: () => import('@/plugins/app@static/academy-course/academy-course.vue'),
		meta: {requiresAuth: undefined}
	},

	{
		path: '/discover',
		name: 'Discover',
		component: () => import('@/plugins/app@static/discover/discover.vue'),
		meta: {requiresAuth: undefined}
	}
]