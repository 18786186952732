<template>
	<ion-app>
		<W-offline-overlay />
		<A-video-upload-progress />
		<ion-router-outlet :animated="!disableAnimations" />
	</ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, modalController } from '@ionic/vue'
import { StatusBar, Style } from '@capacitor/status-bar'
import { SplashScreen } from '@capacitor/splash-screen'
import { Capacitor } from '@capacitor/core'
import axios from 'axios'

import { enablePushNotifications } from '@/plugins/w/notifications/utils'
import wNotifications from '@/plugins/w/notifications'

import WOfflineOverlay from '@/plugins/w@maintenance/offline-overlay/w-offline-overlay.vue'
import WErrorOverlay from '@/plugins/w@maintenance/error-overlay/w-error-overlay.vue'
import AVideoUploadProgress from '@/plugins/app@profile/video-form/_components/a-video-upload-progress.vue'

// user tracking transparency
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency'

export default {
	components: {
		IonApp,
		IonRouterOutlet,
		WOfflineOverlay,
		AVideoUploadProgress
	},

	created() {
		localStorage.setItem('gamechangers_muted_feed', 'false')
		this._setStatusBarColor()
		SplashScreen.hide()
		this.requestTrackingTransparency()
	},

	mounted() {
		wNotifications.install()

		if (this.$store.getters['wAuth/isLoggedIn'] && Capacitor.isNativePlatform) enablePushNotifications()

		// error check
		axios.interceptors.response.use(
			function (res) {
				return res
			},
			(error) => {
				if (error.response.status == 503) {
					this._errorOverlay(error.response.status, error.response.statusText)
				}
				return Promise.reject(error)
			}
		)
	},

	computed: {
		disableAnimations() {
			// Fix na rozbity routing na home (prepinanie feedu) po tom ako sa uzivatel navigoval na profil tvorcu videa
			return this.$route.path == '/feed-for-you' || this.$route.path == '/feed-following' || this.$route.path == '/discover'
		}
	},

	methods: {
		async _errorOverlay(status, statusText) {
			const errorOverlay = await modalController.create({
				component: WErrorOverlay,
				componentProps: {
					status: status,
					statusText: statusText
				},
				style: 'background: #eb445a',
				swipeToClose: true
			})
			return errorOverlay.present()
		},

		_setStatusBarColor() {
			if (!Capacitor.isNativePlatform()) return

			const darkPages = ['Feed - For you', 'Feed - Following', 'Feed - Popular videos', 'Feed - Best rated videos', 'Feed - Profile videos', 'Feed - Subscribed videos', 'Feed - Bookmarked videos', 'Onboarding']

			if (darkPages.includes(this.$route.name)) {
				StatusBar.setStyle({
					style: Style.Dark
				})

				StatusBar.setBackgroundColor({
					color: '#000000'
				})
			} else {
				StatusBar.setStyle({
					style: Style.Light
				})

				StatusBar.setBackgroundColor({
					color: '#ffffff'
				})
			}
		},

		async requestTrackingTransparency() {
			const status = await AppTrackingTransparency.getStatus();
			if(status.status == 'notDetermined') {
				const response = await AppTrackingTransparency.requestPermission();
			}
		}
	},

	watch: {
		$route() {
			this._setStatusBarColor()
		}
	}
}
</script>
