<template>
	<div v-show="isNotificationActive" ref="statusNotification" class="status-notification">
		{{statusMessage}}
		<p>{{statusText}}</p>
	</div>	
</template>

<script>
export default {
	data() {
		return {
			isOnline: true,
			isNotificationActive: false,
			statusMessage: '',
			statusText: ''
		}
	},
    
	mounted () {
		// online status			
		window.addEventListener('online', () => this.isOnline = true)
		window.addEventListener('offline', () => this.isOnline = false)
		this.isOnline = navigator.onLine
	},

	watch: {
		isOnline(bool) {
			if (bool) {
				this.statusMessage = 'Ste späť online!'
				this.statusText = ''
				this.$refs.statusNotification.style.backgroundColor = '#2dd36f'
				setTimeout(() => {
					this.isNotificationActive = false
				}, 1500)
			}
			else {
				this.isNotificationActive = true
				this.statusMessage = 'Ste offline.'
				this.statusText = 'Na používanie aplikácie GameChangers musíte byť pripojený k internetu.'
				this.$refs.statusNotification.style.backgroundColor = '#eb445a'
			}
		}
	}
}
</script>

<style lang="sass" scoped>
.status-notification 
	position: fixed
	top: 0
	width: 100%
	height: 100%
	display: flex
	flex-flow: column wrap
	justify-content: center
	align-items: center
	text-align: center
	color: #f4f5f8
	font-size: 1.5em
	z-index: 50

	p
		position: relative
		width: 80%
		margin: 25px 10%
		color: #f4f5f8
</style>