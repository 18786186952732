import { App } 		from 'vue'
import { Options } 	from './types'

//Components
import CmfInput from '@/plugins/w/cmf/cmf-input/cmf-input.vue'
import CmfSelect from '@/plugins/w/cmf/cmf-select/cmf-select.vue'
import CmfCheckbox from '@/plugins/w/cmf/cmf-checkbox/cmf-checkbox.vue'
import CmfDatePicker from '@/plugins/w/cmf/cmf-date-picker/cmf-date-picker.vue'
import CmfTextarea from '@/plugins/w/cmf/cmf-textarea/cmf-textarea.vue'

export default {
	install: (app: App, options: Options) => {
		if (options && options.disabled === true) return

		app.component('cmf-input', CmfInput)
		app.component('cmf-select', CmfSelect)
		app.component('cmf-checkbox', CmfCheckbox)
		app.component('cmf-date-picker', CmfDatePicker)
		app.component('cmf-textarea', CmfTextarea)
	}
}