export default [
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/plugins/app@auth/login/login.vue'),
		meta: {requiresAuth: false}
	},

	{
		path: '/registration',
		name: 'Registration',
		component: () => import('@/plugins/app@auth/register/register.vue'),
		meta: {requiresAuth: false}
	},

	{
		path: '/forgot-password',
		name: 'Forgot password',
		component: () => import('@/plugins/app@auth/forgot-password/forgot-password.vue'),
		meta: {requiresAuth: false}
	},

	{
		path: '/reset-password',
		name: 'Reset password',
		component: () => import('@/plugins/app@auth/reset-password/reset-password.vue'),
		meta: {requiresAuth: false}
	}
]