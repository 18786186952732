export default [
	{
		path: '/feed-for-you',
		name: 'Feed - For you',
		component: () => import('@/plugins/app@feed/for-you/for-you.vue'),
		meta: {requiresAuth: undefined}
	},

	{
		path: '/feed-following',
		name: 'Feed - Following',
		component: () => import('@/plugins/app@feed/following/following.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/popular-videos/:videoId',
		name: 'Feed - Popular videos',
		component: () => import('@/plugins/app@feed/popular-videos/popular-videos.vue'),
		meta: {requiresAuth: undefined}
	},

	{
		path: '/best-rated-videos/:videoId',
		name: 'Feed - Best rated videos',
		component: () => import('@/plugins/app@feed/best-rated-videos/best-rated-videos.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/profile/:userId/videos/:videoId?',
		name: 'Feed - Profile videos',
		component: () => import('@/plugins/app@feed/profile-videos/profile-videos.vue'),
		meta: {requiresAuth: undefined}
	},

	{
		path: '/my-profile/subscribed-feed/:videoId?',
		name: 'Feed - Subscribed videos',
		component: () => import('@/plugins/app@feed/subscribed-videos/subscribed-videos.vue'),
		meta: {requiresAuth: true}
	},

	{
		path: '/my-profile/bookmarked-feed/:videoId?',
		name: 'Feed - Bookmarked videos',
		component: () => import('@/plugins/app@feed/bookmarked-videos/bookmarked-videos.vue'),
		meta: {requiresAuth: true}
	}
]