import store 			from './store'
import { createApp }	from 'vue'
import App 				from '../App.vue'
import router 			from './router'

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

import '@/plugins/w/universalcss/ionic.sass'
import '../_theme/tailwind/index.css'
import '../_theme/index.sass'

import w from '@/plugins/w/w'

import { Options } from '@/plugins/w/w/types'

const options: Options = {
	i18n: {
		languages: [
			{title: "Slovenský", flag: "sk", value: "sk"},
			{title: "English", flag: "en", value: "en"}
		],
		defaultLanguage: 'sk'
	}
}


const app = createApp(App)
	.use(IonicVue)
	.use(router)
	.use(store)
	.use(w, options)

router.isReady().then(() => {
	app.mount('#app')
})

// x@ts-expect-error @typescript-eslint/ban-ts-comment who knows
// export const $t = app.$i18n.t
